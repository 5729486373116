export const awsConfig = 
    {
        Auth: {
            Cognito: {
                userPoolId: import.meta.env.VITE_USER_POOL_ID || "",
                userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID || "",
                identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID || "",
                loginWith: {
                    oauth: {
                        domain: import.meta.env.VITE_OAUTH_DOMAIN || "",
                        scopes: ['email', 'profile', 'openid', "aws.cognito.signin.user.admin"],
                        redirectSignIn: [import.meta.env.VITE_OAUTH_REDIRECT_IN || ""],
                        redirectSignOut: [import.meta.env.VITE_OAUTH_REDIRECT_OUT || ""],
                        responseType: 'code'
                    }
                }
            },
        },
        Storage: {
            S3: {
                bucket: import.meta.env.VITE_S3_BUCKET,
                region: import.meta.env.VITE_AWS_REGION
            }
        }
    }
